const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const API = {
  ACCOUNTS_BANKS: `${BASE_URL}/accounts/banks`,
  ACCOUNTS_CRYPTO: `${BASE_URL}/accounts/crypto`,
  ACCOUNTS_CRYPTO_NETWORKS: `${BASE_URL}/accounts/crypto-networks`,
  ACCOUNTS_DELETE: `${BASE_URL}/accounts/delete`,
  ACCOUNTS_FIAT: `${BASE_URL}/accounts/fiat`,
  ACCOUNTS_FIAT_SERVICES: `${BASE_URL}/accounts/fiat-services`,
  ACCOUNTS_GAME: `${BASE_URL}/accounts/game`,
  ACCOUNTS_GAMES: `${BASE_URL}/accounts/games`,
  ACCOUNTS_SET: `${BASE_URL}/accounts/set`,
  ADMIN_DISBURSEMENT_BUILD: `${BASE_URL}/admin/disbursement/build`,
  ADMIN_DISBURSEMENT_CONFIRM_TXID: `${BASE_URL}/admin/disbursement/txid`,
  ADMIN_DISBURSEMENT_INVOICE: `${BASE_URL}/admin/disbursement/get_invoice`,
  ADMIN_DISBURSEMENT_GET_PENDING: `${BASE_URL}/admin/disbursements/get_pending`,
  ADMIN_DISBURSEMENT_GET_POSTED_DATA: `${BASE_URL}/admin/disbursement/get_posted_data`,
  ADMIN_DISBURSEMENT_MARK_COMPLETE: `${BASE_URL}/admin/disbursement/mark_complete`,
  ADMIN_DISBURSEMENT_TRANSACTION_MARK_COMPLETE: `${BASE_URL}/admin/transactions/mark_complete`,
  ADMIN_DISBURSEMENT_POST: `${BASE_URL}/admin/disbursement/post`,
  ADMIN_DISBURSEMENT_REJECT: `${BASE_URL}/admin/disbursement`,
  ADMIN_DISBURSEMENT_USD_RATE: `${BASE_URL}/admin/disbursement/usdrate`,
  ADMIN_XENDIT_CREATE_BATCH_DISBURSEMENT: `${BASE_URL}/admin/xendit/createBatchDisbursement`,
  BRIDGE_PROJECTS: `${BASE_URL}/bridge/projects`,
  BRIDGE_ESTIMATE: `${BASE_URL}/bridge/estimate`,
  BRIDGE_MINIMUM: `${BASE_URL}/bridge/minimum`,
  BRIDGE_SPL_BALANCES: `${BASE_URL}/bridge/splinterlands/balances`,
  BRIDGE_SOL_BALANCE: `${BASE_URL}/bridge/solana/sol-balance`,
  BRIDGE_SPL_TOKEN_BALANCE: `${BASE_URL}/bridge/solana/token-balance`,
  BRIDGE_CREATE_SOLANA_TRANSACTION: `${BASE_URL}/bridge/solana/create-transaction`,
  BRIDGE_SEND_SOLANA_SIGNED_TRANSACTION: `${BASE_URL}/bridge/solana/send-transaction`,
  BRIDGE_TOKENS: `${BASE_URL}/bridge/tokens`,
  GUILD_CONNECT: `${BASE_URL}/guild/connect`,
  GUILD_DISCONNECT: `${BASE_URL}/guild/disconnect`,
  GUILD_SETTINGS: `${BASE_URL}/accounts/guild/settings`,
  TRANSACTION: `${BASE_URL}/transaction`,
  TRANSACTION_HISTORY: `${BASE_URL}/transaction/history`,
  TRANSACTION_CONFIRM_TXID: `${BASE_URL}/transaction/confirm/txid`,
  GENERATE_QRCODE: `${BASE_URL}/xendit/create-qr-code`,
  GENERATE_VA: `${BASE_URL}/xendit/create-virtual-account`,
  NETWORKS: `${BASE_URL}/networks`,
  NETWORKS_TOKEN: `${BASE_URL}/networks/token`,
  USER_PROFILE: `${BASE_URL}/user/profile`,
  VENDOR_DISBURSEMENT: `${BASE_URL}/vendor/disbursement/`,
  VENDOR_DISBURSEMENT_APPROVE: `${BASE_URL}/vendor/disbursement/approve/`,
  VENDOR_DISBURSEMENT_COMPLETED: `${BASE_URL}/vendor/disbursement/completed/`,
  VENDOR_DISBURSEMENT_CREATE: `${BASE_URL}/vendor/disbursement`,
  VENDOR_DISBURSEMENT_MILEAGE: `${BASE_URL}/vendor/mileage/`,
  VENDOR_DISBURSEMENT_NETWORK: `${BASE_URL}/vendor/disbursement/network`,
  VENDOR_DISBURSEMENT_PAY: `${BASE_URL}/vendor/disbursement/pay/`,
  VENDOR_DISBURSEMENT_PAYMENT: `${BASE_URL}/vendor/disbursement/payment/`,
  VENDOR_DISBURSEMENT_PROCESSING: `${BASE_URL}/vendor/disbursement/processing/`,
};
