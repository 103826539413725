import {
  FunctionComponent,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';

import { useLazyQuery } from '../../shared/hooks';
import { ConfigurationType } from '../../shared/types';
import supabaseClient from '../services/supabase/supabase.service';

const EXPIRE_SECONDS = 60 * 50;

type GlobalConfigurationContextType = {
  loading: boolean;
  expiredAt: Date | null;
  reload: (logout?: boolean) => void;
  isExpired: () => boolean;
  isMaintenance?: boolean;
  nightMode?: {
    startUtcTime?: string;
    endUtcTime?: string;
  };
};

const GlobalConfigurationContext =
  createContext<GlobalConfigurationContextType>({
    loading: true,
    expiredAt: null,
    reload: () => {
      return;
    },
    isExpired: () => false,
  });

export type GlobalConfigurationProviderProps = {
  children?: ReactNode;
};

const getGlobalConfiguration = async (): Promise<ConfigurationType | null> => {
  const { data, error } = await supabaseClient
    .from('configurations')
    .select('config')
    .eq('type', 'global')
    .single();
  if (error) {
    throw error;
  }

  return data;
};

const GlobalConfigurationProvider: FunctionComponent<
  GlobalConfigurationProviderProps
> = ({ children }) => {
  const [globalConfiguration, setGlobalConfiguration] = useState<
    Omit<GlobalConfigurationContextType, 'reload' | 'isExpired'>
  >({
    loading: true,
    expiredAt: null,
  });

  const isExpired = () => {
    const now = new Date();
    return (
      !globalConfiguration.expiredAt || globalConfiguration.expiredAt < now
    );
  };

  const setErrorStatus = (expire: Date) => {
    setGlobalConfiguration({
      ...globalConfiguration,
      loading: false,
      expiredAt: expire,
    });
  };

  const [query] = useLazyQuery(
    ['globalConfiguration'],
    getGlobalConfiguration,
    {
      onSuccess: data => {
        const expire = new Date();
        expire.setSeconds(expire.getSeconds() + EXPIRE_SECONDS);

        if (!data) {
          setErrorStatus(expire);
        } else {
          setGlobalConfiguration({
            loading: false,
            expiredAt: expire,
            isMaintenance: data.config?.is_maintenance,
            nightMode: {
              startUtcTime: data.config?.night_mode?.start_utc_time,
              endUtcTime: data.config?.night_mode?.end_utc_time,
            },
          });
        }
      },
      onError: () => {
        const expire = new Date();
        expire.setSeconds(expire.getSeconds() + EXPIRE_SECONDS);
        setErrorStatus(expire);
      },
      // No cache
      cacheTime: 0,
    },
  );

  const reload = async () => {
    setGlobalConfiguration({
      ...globalConfiguration,
      loading: true,
    });
    query();
  };

  return (
    <GlobalConfigurationContext.Provider
      value={{
        ...globalConfiguration,
        reload,
        isExpired,
      }}
    >
      {children}
    </GlobalConfigurationContext.Provider>
  );
};

const useGlobalConfigurationContext = () =>
  useContext(GlobalConfigurationContext);

export {
  GlobalConfigurationContext,
  GlobalConfigurationProvider,
  useGlobalConfigurationContext,
};
