import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  QueryFunction,
  QueryKey,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseQueryOptions,
  UseQueryResult,
  useMutation as useMut,
  useQuery,
} from '@tanstack/react-query';

import qs from 'qs';

import { ApplicationMode } from './types';
import { isTimeBetween } from './datetime';
import { useGlobalConfigurationContext } from '../core/lib/globalConfigurationContext';
import { MutationHttpMethod, ParamsType } from './types';
import { useAuthStore } from 'src/core/zustand/store/authStore/authStore';

export const usePaths = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);

  const onBack = () => {
    const keys = Object.keys(params);
    if (params.ref) {
      navigate(params.ref as string, { replace: true });
    } else {
      if (keys[keys.length - 1] === 'q') {
        delete params[keys[keys.length - 1]];
        navigate(`${pathname}`, { replace: true });
      } else {
        delete params[keys[keys.length - 1]];
        navigate(`${pathname}?${qs.stringify(params)}`, { replace: true });
      }
    }
  };

  return { onBack, params, pathname, navigate, search };
};

export const useCopyToClipboard = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isCopied, setCopied] = useState(false);

  const onCopy = () => {
    inputRef.current && inputRef.current.select();
    inputRef.current &&
      inputRef.current.setSelectionRange(0, 99999); /* For mobile devices */

    navigator.clipboard.writeText(
      (inputRef.current && inputRef.current.value) || '',
    );
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return {
    inputRef,
    isCopied,
    onCopy,
  };
};

export const useFetch = (key: string, path: string, params?: ParamsType) => {
  const { accessToken } = useAuthStore();
  // const accessToken = getToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const fetchOptions = {
    ...params?.init,
    headers: headers,
  };
  return useQuery(
    [key, params?.variables],
    async () => {
      return fetch(path, fetchOptions).then(d => d.json());
    },
    {
      retry: 3,
      retryDelay: 500,
      onError: (err: Error) => {
        toast.error(`Error in fetching data: ${err.message}`);
      },
      ...params?.options,
    },
  );
};

export const useMutation = <TData>(
  path: string,
  params?: ParamsType,
  method: MutationHttpMethod = MutationHttpMethod.POST,
) => {
  return useMut(
    (formData: TData) => {
      const { accessToken } = useAuthStore();
      // const accessToken = getToken();
      return fetch(path, {
        method,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        ...params?.init,
      }).then(d => d.json());
    },
    {
      retry: 3,
      retryDelay: 500,
      onError: (err: Error) => {
        toast.error(`Error in mutating data: ${err.message}`);
      },
      ...params?.options,
    },
  );
};

export const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

type QueryRefetchType = <TPageData>(
  options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
) => Promise<QueryObserverResult<unknown, unknown>>;
export const useLazyQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  fn: QueryFunction<TQueryFnData, QueryKey>,
  options: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: () => undefined;
  } = {},
): readonly [QueryRefetchType, UseQueryResult<unknown, unknown>] => {
  const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(key, fn, {
    ...options,
    enabled: false,
  });

  return [query.refetch, query];
};

export const useApplicationMode = (): ApplicationMode => {
  try {
    const config = useGlobalConfigurationContext();
    if (config.isMaintenance) {
      return ApplicationMode.Maintenance;
    }

    const nightModeStartTime = config?.nightMode?.startUtcTime;
    const nightModeEndTime = config?.nightMode?.endUtcTime;
    if (
      nightModeStartTime &&
      nightModeEndTime &&
      isTimeBetween(nightModeStartTime, nightModeEndTime)
    ) {
      return ApplicationMode.NightMode;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(`Error in useApplicationMode hook: ${e.message}`);
  }

  return ApplicationMode.Online;
};

export const useRetryTransaction = (
  transaction: any,
  refetch: () => void,
  options: {
    enabled: boolean;
  } = { enabled: true },
) => {
  const retryRef = useRef<any>(null);

  const onClearInterval = () => {
    clearInterval(retryRef.current);
  };

  useEffect(() => {
    if (transaction && transaction?.status !== 2 && options?.enabled) {
      retryRef.current = setInterval(() => {
        refetch();
      }, 6000);
    } else {
      if (retryRef.current) {
        onClearInterval();
      }
    }

    return () => {
      onClearInterval();
    };
  });

  return { onClearInterval, retryRef };
};

export const useDeviceType = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in (navigator as any)) {
      hasTouchScreen = (navigator as any).msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true;
      } else {
        const UA = (navigator as any).userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    setIsMobile(hasTouchScreen);
  }, []);

  return isMobile;
};
