import { ThemeProvider } from '@mui/material/styles';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Toaster, toast } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import App from './app/app';
import Alert from './components/Alert/index.component';
import { GlobalConfigurationProvider } from './core/lib/globalConfigurationContext';
import './core/lib/i18n';
import Navigation from './router/Navigation';
import { theme } from './shared/styles';
import { SuccessResponseType } from './shared/types';
import { DialogProvider } from './core/lib/dialogContext';
import { ConfirmDialog } from './components/Dialog/ConfirmDialog.component';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onSuccess: data => {
      const d = data as SuccessResponseType;
      if (d.statusCode === 500) {
        toast.custom(t => (
          <Alert t={t} title={d.error} description={d.message} type="error" />
        ));
      }
    },
  }),
});

root.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <GlobalConfigurationProvider>
            <DialogProvider>
              <App>
                <Navigation />
              </App>
              <Toaster position="bottom-right" />
              <ConfirmDialog />
            </DialogProvider>
          </GlobalConfigurationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
);
