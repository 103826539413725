import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';

type Props = {
  title?: string;
  description?: string;
  type?: 'success' | 'error';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: any;
};

const icons = {
  success: <BsFillCheckCircleFill fontSize={30} className="text-green-600" />,
  error: <BsFillXCircleFill fontSize={30} className="text-red-600" />,
};

const Alert: React.FC<Props> = ({
  t,
  type = 'success',
  title,
  description,
}) => {
  const translation = useTranslation();
  return (
    <div
      className={`${
        t?.visible ? 'animate-enter' : 'animate-leave'
      } max-w-sm w-full bg-gray-800 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 text-white`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start ">
          <div className="flex-shrink-0 pt-0.5">
            <div>{icons[type]}</div>
          </div>
          <div className="ml-3 flex-1 overflow-hidden">
            <p className="text-base font-medium">{title}</p>
            {description ? (
              <p className="mt-1 line-clamp-2 text-sm text-gray-300">
                {description}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-900">
        <button
          onClick={() => toast.remove(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          {translation.t('Close')}
        </button>
      </div>
    </div>
  );
};

export default Alert;
