export type TokenType = {
  id: string;
  name: string;
  description: string;
  img: string;
  code?: string;
};

export type BankType = {
  id: string;
  title: string;
  code: string;
};

export type FormatDateType = {
  date?: string;
  format?: string;
};

export type ParamsType = {
  options?: object;
  init?: object;
  variables?: Record<string, any>;
};

export type NetworksType = {
  name: string;
  code: string;
};

export type EstimatedTokenType = {
  pair: string;
  side: string;
  safe_price?: number;
  input_amount?: number;
  estimated_amount: number;
};

export type AcceptTransaction = {
  account_name: string;
  account_number: string;
  amount: number;
  created_at: string;
  currency: string;
  guild: string;
  id: string;
  request_date: string;
  service_name: string;
  status: number;
  user_id: string;
  address: string;
  tx_id: string;
};

export type NewTransactionType = {
  accessToken?: string;
  token: string;
  network: string;
  address: string;
  side: string;
  quantity: string | number | null;
  estimatedOutput: number;
  fiatAccountId?: number;
};

export type MutationResponseType = {
  success: boolean;
  data: {
    id: string;
  };
};

export type QRCodeDataType = {
  id: string;
  external_id: string;
  amount: number;
  qr_string: string;
  status: string;
  callback_url?: string;
  description?: string;
  type?: string;
  created?: string;
  updated?: string;
  metadata?: null | string;
};

type QRCallbackDataType = {
  id: string;
  event: string;
  amount: number;
  status: string;
  created: string;
  qr_code: {
    id: string;
    type: string;
    metadata?: null;
    qr_string: string;
    external_id: string;
  };
  payment_details: {
    source: string;
    receipt_id: string;
  };
};

type VACallbackDataType = {
  id: string;
  payment_id: string;
  callback_virtual_account_id: string;
  owner_id?: string;
  external_id: string;
  account_number: string;
  bank_code: string;
  transaction_timestamp: string;
  amount: number;
  merchant_code?: string;
  currency: string;
  country: string;
  sender_name?: string;
  payment_detail: {
    payment_interface: string;
    remark: string;
    reference: string;
    sender_account_number: string;
    sender_channel_code: string;
    sender_name: string;
    transfer_method: string;
  };
};

type TransactionIDType = {
  id: string;
  transaction_id: string;
  payment_method: string;
  xendit_id: string;
  xendit_paid_id: null | string;
  currency: string;
  amount: number;
  data: QRCodeDataType | VAInvoiceIDType | null | string;
  callback_data: QRCallbackDataType | VACallbackDataType | null;
  tx_id: null | string;
  address: null | string;
  expired_at: string;
  status: number;
};

export interface IBridgeTrade {
  id?: string;
  created_at?: string;
  transaction_id?: string;
  side?: string;
  pair?: string;
  amount?: number;
  quote_amount?: number;
  transit?: string;
  transit_amount?: number;
  data?: object;
  status?: number;
  fees?: object;
}

export interface IBridgeDisbursement {
  id?: string;
  created_at?: string;
  transaction_id?: string;
  amount?: number;
  currency?: string;
  xendit_id?: string;
  bank_code?: string;
  account_number?: string;
  account_name?: string;
  address?: string;
  network?: string;
  tx_id?: string;
  data?: unknown;
  status?: number;
}

export type TransactionResponseType = {
  id?: string;
  user_id: string;
  side: string;
  token: string;
  quantity: number;
  invoice_id: TransactionIDType | null;
  disbursement_id: IBridgeDisbursement | null;
  trade_id: IBridgeTrade | null;
  estimated_output: number;
  final_output: number | null;
  status: number;
  created_at: string;
  expired_at: string;
  network: string;
  address: string;
  fiat_account?: {
    bank_code: string;
    account_name: string;
    account_number: string;
  };
};

export type GenerateQRCodeType = {
  transaction_id: string;
};

export type GenerateVAType = {
  transaction_id: string;
  bank_code: string;
};

export type GenerateQRCodeResponseType = {
  success: boolean;
  data: QRCodeDataType;
};

export type VAInvoiceIDType = {
  id: string;
  external_id: string;
  bank_code: string;
  account_number: string;
  name: string;
  is_single_use: boolean;
  is_closed: boolean;
  expected_amount: number;
  expiration_date: string;
  status: string;
  owner_id?: string;
  merchant_code?: string;
  currency?: string;
  country?: string;
};

export type GenerateVAResponseType = {
  success: boolean;
  data: VAInvoiceIDType;
};

export type SuccessResponseType = {
  statusCode: number;
  error: string;
  message: string;
};

export type TokenNetworkType = {
  [key: string]: boolean | string;
  active: boolean;
  network: string;
  code: string;
  description: string;
  buy: boolean;
  sell: boolean;
};

export type TokenNetworksType = {
  id: string;
  name: string;
  networks?: TokenNetworkType[];
  minimum_trade_quantity?: number;
  categories?: string[];
  current_price: number
  market_cap?: number
  price_change_percentage_24h: number;
};

export type BridgeEstimateResponseType = {
  success: boolean;
  amount_in?: number;
  amount_out?: number;
  pair?: string;
  side?: string;
  error?: string;
};

export type ConfigurationType = {
  id: number;
  type: string;
  config?: {
    is_maintenance?: boolean;
    night_mode?: {
      start_utc_time: string;
      end_utc_time: string;
    };
  };
};

export enum MutationHttpMethod {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum LanguageKey {
  English = 'en',
  Indonesian = 'id',
}

export enum ApplicationMode {
  Online = 0,
  Maintenance = 1,
  NightMode = 2,
}

export enum NetworkEnum {
  AVALANCHE = 'AVALANCHE',
  BSC = 'BSC',
  BTC = 'BTC',
  CARDANO = 'CARDANO',
  DOGE = 'DOGE',
  ETH = 'ETH',
  RON = 'RON',
  IDR = 'IDR',
  LTC = 'LTC',
  POLKADOT = 'POLKADOT',
  SPLINTERLANDS = 'SPL',
  SOLANA = 'SOLANA',
  STELLAR = 'STELLAR',
  TRON = 'TRON',
  POLYGON = 'POLYGON',
  RIPPLE = 'RIPPLE',
}

export type FiatServices = {
  id: number;
  name: string;
  currency: string;
}

export interface IUsersFiatAccount {
  id?: number;
  service_id: number;
  account_number?: string;
  account_name?: string;
  currency: string;
  address?: string;
}

export interface IUserCryptoAccount {
  id?: number;
  label?: string;
  network: string;
  address: string;
}

export interface IUserGameAccount {
  id?: number;
  label?: string;
  game: string;
  game_id: string;
}

export interface IConnectionSetting {
  id: number;
  name: string;
  type: 'fiat' | 'crypto' | 'game';
}

export interface ISetting {
  [key: string]: IConnectionSetting
}

export interface IProfiles {
  id?: string;
  name?: string;
  email?: string;
  provider?: string;
  provider_id?: string;
  avatar_url?: string;
  guilds?: string[];
  settings?: ISetting;
}